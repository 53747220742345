import React from "react";
import {
  Center,
  Container,
  Heading,
  Stack,
  Box,
  Image,
  Link,
  Text,
  Divider,
  Spacer,
  UnorderedList,
  ListItem,
} from "@chakra-ui/react";

const NomenclatureSection = (props) => (
  <Container maxWidth="7xl">
    <Heading size="lg" color="gray" marginBottom="1em" align="center">
      Genetic Nomenclature
    </Heading>
    <Container maxW="7xl" borderWidth="1px" borderRadius="lg" p="15vh">
      <Stack>
        <UnorderedList>
          <ListItem>
            <strong>Human:</strong> HGNC{" "}
            <Link href="https://www.genenames.org/" isExternal>
              https://www.genenames.org
            </Link>{" "}
            <Link href="https://www.genenames.org/about/guidelines/" isExternal>
              https://www.genenames.org/about/guidelines
            </Link>
          </ListItem>
          <ListItem>
            <strong>Mouse:</strong>{" "}
            <Link href="https://www.informatics.jax.org/" isExternal>
              https://www.informatics.jax.org
            </Link>{" "}
            <Link
              href="https://www.informatics.jax.org/mgihome/nomen/gene.shtml"
              isExternal
            >
              https://www.informatics.jax.org/mgihome/nomen/gene.shtml
            </Link>
          </ListItem>
          <ListItem>
            <strong>Rat:</strong>{" "}
            <Link href="https://rgd.mcw.edu/" isExternal>
              https://rgd.mcw.edu
            </Link>{" "}
            <Link href="https://rgd.mcw.edu/nomen/nomen.shtml" isExternal>
              https://www.rgd.mcw.edu/nomen/nomen.shtml
            </Link>
          </ListItem>
          <ListItem>
            <strong>Arabidopsis:</strong>
            <Link
              href="https://www.arabidopsis.org/portals/nomenclature/symbol_main.jsp"
              isExternal
            >
              https://www.arabidopsis.org/portals/nomenclature/symbol_main.jsp
            </Link>
          </ListItem>
          <ListItem>
            <strong>Fly:</strong>{" "}
            <Link href="https://flybase.org/" isExternal>
              https://flybase.org
            </Link>{" "}
            <Link
              href="https://flybase.org/wiki/FlyBase:Nomenclature"
              isExternal
            >
              https://flybase.org/wiki/FlyBase:Nomenclature
            </Link>
          </ListItem>
          <ListItem>
            <strong>Nematode:</strong>{" "}
            <Link href="https://www.wormbase.org" isExternal>
              https://www.wormbase.org
            </Link>{" "}
            <Link
              href="https://www.wormbase.org/about/userguide/nomenclature#c4hfgjdeb63ki70al1958m2–10-fgjdeb63i7"
              isExternal
            >
              https://www.wormbase.org/about/userguide/nomenclature#c4hfgjdeb63ki70al1958m2–10-fgjdeb63i7
            </Link>
          </ListItem>
          <ListItem>
            <strong>Yeast:</strong> (S. cerevisae){" "}
            <Link href="https://www.yeastgenome.org/" isExternal>
              https://www.yeastgenome.org
            </Link>{" "}
            <Link
              href="https://www.yeastgenome.org/help/community/nomenclature-conventions"
              isExternal
            >
              https://www.yeastgenome.org/help/community/nomenclature-conventions
            </Link>
          </ListItem>
          <ListItem>
            <strong>Yeast:</strong> (S. pombe){" "}
            <Link href="https://www.pombase.org" isExternal>
              https://www.pombase.org
            </Link>{" "}
            <Link
              href="https://www.pombase.org/submit-data/gene-naming-guidelines"
              isExternal
            >
              https://www.pombase.org/submit-data/gene-naming-guidelines
            </Link>
          </ListItem>
          <ListItem>
            <strong>Xenopus:</strong>{" "}
            <Link
              href="http://www.xenbase.org/gene/static/geneNomenclature.jsp"
              isExternal
            >
              http://www.xenbase.org/gene/static/geneNomenclature.jsp
            </Link>
          </ListItem>
          <ListItem>
            <strong>Zebrafish:</strong>{" "}
            <Link href="https://www.zfin.org" isExternal>
              https://www.zfin.org
            </Link>
            <Link
              href="https://wiki.zfin.org/display/general/ZFIN+Zebrafish+Nomenclature+Guidelines"
              isExternal
            >
              https://wiki.zfin.org/display/general/ZFIN+Zebrafish+Nomenclature+Guidelines
            </Link>
          </ListItem>
        </UnorderedList>
        <p>
          New names should be approved, as appropriate for a given model
          organism, prior to micropublication submission.
        </p>
      </Stack>
    </Container>
  </Container>
);

export default NomenclatureSection;
