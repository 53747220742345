import React from "react";

import Layout from "../components/Layout";
import NomenclatureSection from "../content/NomenclatureSection";

const GeneticNomenclature = (props) => {
    return (
        <Layout>
            <NomenclatureSection />
        </Layout>
    );
};

export default GeneticNomenclature;
